#ThingToDoName {
  top: 33%;
  font-size: 30px;
}

@media only screen and (max-width: 400px) {
  #ThingToDoName {
    top: 32%;
    font-size: 29px;
  }
}

@media only screen and (max-width: 320px) {
  #ThingToDoName {
    top: 30%;
    font-size: 28px;
  }
}

@media only screen and (max-width: 280px) {
  #ThingToDoName {
    top: 28%;
    font-size: 26px;
  }
}

@media only screen and (max-width: 250px) {
  #ThingToDoName {
    top: 27%;
    font-size: 24px;
  }
}
