.CardContentContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.CardName {
  color: white;
  font-family: Anuphan;
  font-weight: bold;
  text-shadow: 2px 2px 2px black;
  position: absolute;
  top: 33%;
  font-size: 32px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.CardName:hover {
  text-decoration: none;
  text-shadow: 3px 3px 3px black;
}

.CardName:active {
  color: black;
  text-shadow: 2px 2px 2px white;
}

.GGOGIconContainer {
  position: absolute;
  text-align: left;
  left: 10%;
  top: 5%;
}

.GGOGIcon {
  width: 15%;
  pointer-events: none;
}

#RatingStarsContainer {
  justify-content: center;
  width: 100%;
}

.TravelCard {
  height: 350px;
  width: 350px;
  justify-content: center;
  display: flex;
  margin: auto;
  position: relative;
  cursor: default;
}

.TravelCardThumbnailImage {
  width: 100%;
  pointer-events: none;
}

/* This is old css which centered the photo and cropped the overflow */
/* .TravelCardThumbnailImage {
  object-fit: cover;
  object-position: center;
} */

@media only screen and (max-width: 1100px) {
  .TravelCard {
    height: 315px;
    width: 315px;
  }
  .CardName {
    font-size: 29px;
  }
}

@media only screen and (max-width: 1000px) {
  .TravelCard {
    height: 350px;
    width: 350px;
  }
  .CardName {
    font-size: 32px;
  }
}

@media only screen and (max-width: 750px) {
  .TravelCard {
    height: 315px;
    width: 315px;
  }
  .CardName {
    font-size: 31px;
  }
}

@media only screen and (max-width: 700px) {
  .CardName {
    font-size: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .TravelCard {
    height: 450px;
    width: 450px;
  }
  .CardName {
    font-size: 40px;
  }
}

@media only screen and (max-width: 475px) {
  .TravelCard {
    height: 425px;
    width: 425px;
  }
  .CardName {
    font-size: 38px;
  }
}

@media only screen and (max-width: 425px) {
  .TravelCard {
    height: 400px;
    width: 400px;
  }
  .CardName {
    font-size: 36px;
  }
}

@media only screen and (max-width: 400px) {
  .TravelCard {
    height: 375px;
    width: 375px;
  }
  .CardName {
    font-size: 34px;
  }
}

@media only screen and (max-width: 375px) {
  .TravelCard {
    height: 350px;
    width: 350px;
  }
  .CardName {
    font-size: 32px;
  }
}

@media only screen and (max-width: 350px) {
  .TravelCard {
    height: 325px;
    width: 325px;
  }
  .CardName {
    font-size: 31px;
  }
}

@media only screen and (max-width: 325px) {
  .TravelCard {
    height: 300px;
    width: 300px;
  }
  .CardName {
    font-size: 30px;
    top: 30%;
  }
}

@media only screen and (max-width: 310px) {
  .CardName {
    font-size: 29px;
  }
}

@media only screen and (max-width: 300px) {
  .TravelCard {
    height: 275px;
    width: 275px;
  }
  .CardName {
    font-size: 28px;
  }
}

@media only screen and (max-width: 275px) {
  .TravelCard {
    height: 250px;
    width: 250px;
  }
  .CardName {
    font-size: 27px;
  }
}

@media only screen and (max-width: 250px) {
  .TravelCard {
    height: 233px;
    width: 233px;
  }
  .CardName {
    font-size: 26px;
  }
}
