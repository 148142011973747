.RatingCriteriaFlag {
  width: 24px;
  height: 24px;
  margin: 1px;
}

.RatingFlagsContainer {
  text-align: center;
}

#RatingInfo {
  text-align: center;
  margin-bottom: 5px;
}

.RatingInfoButtonContainer {
  text-align: center;
  margin-bottom: 5px;
}

.RatingInfoButton {
  background-color: #02674c;
  margin-top: 10px;
  text-align: center;
  color: white;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  border: none;
}

.RatingInfoButton:focus {
  outline: none;
}

.RatingInfoButton:hover {
  color: white;
  text-decoration: none;
  text-shadow: 1px 1px 1px black;
  text-decoration: none;
}

.RatingInfoButton:active {
  color: black;
  text-shadow: 1px 1px 1px white;
  text-decoration: none;
}
