#SupplierName {
  padding-bottom: 0px;
}

#SupplierLocation {
  padding-top: 0px;
}

.RatingRow {
  margin-bottom: 5px;
}

.MemberLogoContainer {
  text-align: center;
}

.MemberLogoMainImageContainer {
  position: absolute;
  top: 8%;
  right: 4%;
  animation: fadeIn 4s;
}

.MemberLogo {
  border: solid white 2px;
  border-radius: 10px;
  width: 75px;
  margin-bottom: 15px;
}

.MemberLogoMainImage {
  border: solid white 2px;
  border-radius: 10px;
  width: 100px;
  margin-bottom: 15px;
}

.SupplierLogoContainer {
  text-align: center;
  margin-bottom: 10px;
}

.SupplierLogo {
  width: 120px;
  pointer-events: none;
}

@media only screen and (max-width: 2000px) {
  .MemberLogoMainImage {
    width: 90px;
  }
}

@media only screen and (max-width: 1750px) {
  .MemberLogoMainImage {
    width: 80px;
  }
}

@media only screen and (max-width: 1500px) {
  .MemberLogoMainImage {
    width: 70px;
  }
  .MemberLogoMainImageContainer {
    right: 3%;
    top: 12%;
  }
}

@media only screen and (max-width: 1100px) {
  .MemberLogoMainImage {
    width: 65px;
  }
}

@media only screen and (max-width: 991px) {
  .MemberLogoMainImageContainer {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .SuppliersBackButtonsContainer {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .MemberLogo {
    width: 70px;
  }
}

@media only screen and (max-width: 350px) {
  .MemberLogo {
    width: 65px;
  }
}

@media only screen and (max-width: 300px) {
  .MemberLogo {
    width: 60px;
  }
}
