#EmojiRow {
  text-align: center;
  font-size: 35px;
  cursor: default;
}

#RestaurantPubLocationFlag {
  margin-left: 5px;
  margin-bottom: 10px;
}

.RestaurantBritishAleEmoji {
  margin-bottom: 10px;
  margin-right: 5px;
  height: 45px;
}

.RestaurantFoodSpecialEmoji {
  margin-left: 5px;
  margin-bottom: 10px;
  height: 45px;
}
