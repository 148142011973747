#LegalRow {
  width: 90%;
  margin: auto;
}

.LegalLinks {
  color: white;
  margin: auto;
}

.LegalLinks:hover {
  color: white;
  text-shadow: 1px 1px 1px black;
  text-decoration: none;
}

.LegalLinks:active {
  color: black;
  text-shadow: 1px 1px 1px white;
  text-decoration: none;
}

#PrivacyPolicyLink {
  text-align: right;
}

#TermsofUseLink {
  text-align: left;
}

@media only screen and (max-width: 750px) {
  #PrivacyPolicyLink {
    text-align: center;
  }
  #TermsofUseLink {
    text-align: center;
  }
}

@media only screen and (max-width: 550px) {
  #LegalRow {
    margin-top: 10px;
  }
}
