
.BackButton {
  margin-top: 10px;
  margin-left: 10px;
  background-color: #007bc0;
  border-radius: 5px;
  border-color: white;
  border-width: 1px;
  color: white;
  font-size: 18px;
  font-family: "Anuphan", sans-serif;
  position: sticky;
  top: 5%;
  z-index: 2;
}

.BackButton:hover {
  cursor: pointer;
  text-decoration: none;
  text-shadow: 1px 1px 1px black;
}

#BackButtonFlag {
  height: 18px;
  width: 18px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 0px;
}

.BackButton:focus {
  outline: none;
}

.BackButton:active {
  cursor: pointer;
  text-decoration: none;
  text-shadow: 1px 1px 1px white;
  color: black;
  outline: none;
}

@media only screen and (max-width: 600px) {
  .BackButtonContainer {
    margin-top: 15px;
    margin-left: 5px;
  }
}
