.CardButton {
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: white;
    background-color: #02674c;
    outline: none !important;
    border-radius: 10px;
    border: 1px solid white;
  }
  
  .CardButton:hover {
    text-shadow: 2px 2px 2px black;
    cursor: pointer;
  }
  
  .CardButton:active {
    color: black;
    text-shadow: white 1px 1px 1px;
  }
  
  .CardButton:active:after {
    text-shadow: 2px 2px 2px black;
  }
  
  .CardButton:before {
    margin-right: 5px;
    /* content: "⛳"; */
    visibility: hidden;
    content: url(../../../Assets/FlagIcon.png);
  }
  
  .CardButton:after {
    margin-left: 5px;
    /* content: "⛳"; */
    visibility: hidden;
    content: url(../../../Assets/FlagIcon.png);
  }
  
  .CardButton:hover::after {
    visibility: visible;
  }
  
 .CardButtonContainer {
    width: 100%;
    position: absolute;
    text-align: center;
    top: 80%;
  }
  
  @media only screen and (max-width: 1200px) {
    .CardButton {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 1100px) {
    .CardButton {
      font-size: 19px;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .CardButton {
      font-size: 18px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .CardButton {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 350px) {
    .CardButton:before {
      margin-right: 4px;
    }
    .CardButton:after {
      margin-left: 4px;
    }
  }

  @media only screen and (max-width: 325px) {
    .CardButton {
      font-size: 18px;
    }
  }
  
  @media only screen and (max-width: 300px) {
    .CardButton {
      font-size: 16px;
    }
  }
  
  @media only screen and (max-width: 275px) {
    .CardButtonContainer {
      width: 95%;
    }
    .CardButton {
      font-size: 14px;
    }
    .CardButton:before {
      margin-right: 2px;
    }
    .CardButton:after {
      margin-left: 2px;
    }
    }
  
  @media only screen and (max-width: 250px) {
    .CardButton {
      font-size: 13px;
    }
    .CardButtonContainer {
      top: 85%;
    }
  }