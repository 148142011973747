#RegionName {
  top: 30%;
  font-size: 28px;
}

#RegionSubtitle {
  text-align: center;
  color: white;
  font-size: 20px;
  font-family: Anuphan;
  font-weight: bold;
  text-shadow: 2px 2px 2px black;
  cursor: default;
  width: 100%;
  /* position: absolute;
  top: 43%; */
  cursor: inherit;
}

#RegionSubtitle:hover {
  cursor: pointer;
  text-shadow: 3px 3px 3px black;
}

#RegionSubtitle:active {
  color: black;
  text-shadow: 2px 2px 2px white;
}

@media only screen and (max-width: 1100px) {
  #RegionName {
    font-size: 25px;
  }
  #RegionSubtitle {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1000px) {
  #RegionName {
    font-size: 28px;
  }
  #RegionSubtitle {
    font-size: 20px;
  }
}

@media only screen and (max-width: 750px) {
  #RegionName {
    font-size: 26px;
  }
  #RegionSubtitle {
    font-size: 19px;
  }
}

@media only screen and (max-width: 700px) {
  #RegionName {
    font-size: 25px;
  }
  #RegionSubtitle {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  #RegionName {
    font-size: 36px;
  }
  #RegionSubtitle {
    font-size: 28px;
  }
}

@media only screen and (max-width: 475px) {
  #RegionName {
    font-size: 34px;
  }
  #RegionSubtitle {
    font-size: 26px;
  }
}

@media only screen and (max-width: 425px) {
  #RegionName {
    font-size: 32px;
  }
  #RegionSubtitle {
    font-size: 24px;
  }
}

@media only screen and (max-width: 400px) {
  #RegionName {
    font-size: 30px;
  }
  #RegionSubtitle {
    font-size: 22px;
  }
}

@media only screen and (max-width: 375px) {
  #RegionName {
    font-size: 29px;
  }
  #RegionSubtitle {
    font-size: 20px;
  }
}

@media only screen and (max-width: 350px) {
  #RegionName {
    font-size: 27px;
  }
  #RegionSubtitle {
    font-size: 19px;
  }
}

@media only screen and (max-width: 325px) {
  #RegionName {
    font-size: 25px;
  }
  #RegionSubtitle {
    font-size: 18px;
  }
}

@media only screen and (max-width: 310px) {
  #RegionName {
    font-size: 24px;
  }
  #RegionSubtitle {
    font-size: 17px;
  }
}

@media only screen and (max-width: 300px) {
  #RegionName {
    font-size: 23px;
  }
  #RegionSubtitle {
    font-size: 16px;
  }
}

@media only screen and (max-width: 275px) {
  #RegionName {
    font-size: 22px;
  }
  #RegionSubtitle {
    font-size: 15px;
  }
}

@media only screen and (max-width: 250px) {
  #RegionName {
    font-size: 21px;
  }
  #RegionSubtitle {
    font-size: 14px;
  }
}
