.MainImageButton {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: white;
  text-shadow: 3px 3px 3px black;
  background-color: #02674c;
  outline: none !important;
  border-radius: 10px;
  border: 1px solid white;
  padding-left: 10px;
  padding-right: 10px;
  animation: fadeIn 4s;
}

.MainImageButton:hover {
  cursor: pointer;
}

.MainImageButton:active {
  color: black;
  text-shadow: white 2px 2px 2px;
}

.MainImageButton:active:after {
  text-shadow: 3px 3px 3px black;
}

.MainImageButton:before {
  margin-right: 10px;
  content: "⛳";
  visibility: hidden;
}

.MainImageButton:after {
  margin-left: 10px;
  content: "⛳";
  visibility: hidden;
}

.MainImageButton:hover::after {
  visibility: visible;
}

@media only screen and (max-width: 1199px) {
  .MainImageButton {
    font-size: 26px;
  }
}

@media only screen and (max-width: 991px) {
  .MainImageButton {
    font-size: 24px;
  }
}

@media only screen and (max-width: 700px) {
    .MainImageButton {
      font-size: 22px;
    }
  }

  @media only screen and (max-width: 425px) {
    .MainImageButton {
      font-size: 21px;
    }
  }

  @media only screen and (max-width: 350px) {
    .MainImageButton {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 325px) {
    .MainImageButton {
      font-size: 19px;
    }
  }

  @media only screen and (max-width: 300px) {
    .MainImageButton {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 275px) {
    .MainImageButton {
      font-size: 17px;
    }
  }

  @media only screen and (max-width: 250px) {
    .MainImageButton {
      font-size: 16px;
    }
  }