#Starter {
  padding: 20px;
  overflow-x: hidden;
}

#StarterFormMobile {
  display: none;
}

#ScrollToPoint {
  position: absolute;
  bottom: 8%;
}

#StarterImageContainer {
  height: 0;
  /* Formula is: (height / width * 100%) */
  padding-top: calc(900 / 1600 * 100%);
}

#StarterImageContainerMobile {
  height: 0;
  /* Formula is: (height / width * 100%) */
  padding-top: calc(960 / 1280 * 100%);
  display: none;
}

#StarterImageContainerMobileBottom {
  height: 0;
  /* Formula is: (height / width * 100%) */
  padding-top: calc(960 / 1280 * 100%);
  display: none;
}

#StarterImage {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  /* max-width: 100%; */
  height: auto;
}

#StarterLogo {
  position: absolute;
  top: 11%;
  width: 30%;
  pointer-events: none;
}

#StarterText {
  position: absolute;
  top: 31%;
}

.StarterFieldTitle {
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 20px;
  font-family: "Anuphan", sans-serif;
  font-weight: 500;
}

.StarterFieldTitle:before {
  content: url(../../Assets/FlagIcon.png);
  margin-right: 5px;
}

#StarterHeadline {
  padding-bottom: 0px;
}

#StarterSubHeadline {
  padding-top: 0px;
}

.SubmitButtonContainer {
  text-align: center;
}

#SubmitButtonContainerMobile {
  text-align: center;
  display: none;
}

.SubscribeText {
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 20px;
  font-family: "Anuphan", sans-serif;
  font-weight: 500;
}

@media only screen and (max-width: 1300px) {
  #StarterLogo {
    top: 12%;
  }
  #StarterText {
    top: 32%;
  }
}

@media only screen and (max-width: 1200px) {
  #StarterLogo {
    top: 13%;
  }
  #StarterText {
    top: 33%;
  }
}

@media only screen and (max-width: 991px) {
  #ScrollToPoint {
    bottom: 25%;
  }

  #StarterLogo {
    width: 33%;
    top: 10%;
  }
  #StarterText {
    top: 26%;
  }
  #StarterImageContainer {
    display: none;
  }
  #StarterImageContainerMobile {
    display: flex;
  }
  #StarterImageContainerMobileBottom {
    display: flex;
    margin-bottom: 25px;
  }
  #StarterFormMobile {
    display: block;
  }
  #StarterFormDesktop {
    display: none;
  }
}

@media only screen and (max-width: 350px) {
  #StarterHeadline {
    padding: 2px;
  }
}

@media only screen and (max-width: 260px) {
  #StarterHeadline {
    font-size: 20px;
  }
}

@media only screen and (max-width: 250px) {
  #StarterHeadline {
    font-size: 18px;
  }
}
