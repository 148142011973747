#Footer {
  font-family: "Lato", sans-serif;
  text-align: center;
  font-weight: 500;
  color: white;
  margin-top: 10px;
  cursor: default;
  bottom: 0;
}

#FooterFlag {
  margin-bottom: 3px;
}

#FooterLogo {
  width: 130px;
  border-radius: 2px;
  box-shadow: 0px 0px 2px 2px #363695;
}

.FooterText {
  vertical-align: bottom;
  padding: 10px;
}

#FooterContactInfo {
  margin-bottom: 5px;
}

#FooterIcon {
  width: 100px;
}

@media only screen and (max-width: 991px) {
  #FooterIcon {
    width: 90px;
  }
}
