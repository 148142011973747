#Home {
  overflow-x: hidden;
}

#HeroImageContainer {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 0;
  /* Formula is: (height / width * 100%) */
  padding-top: calc(1350 / 1800 * 100%);
}

#HeroImageContainerMobile {
  display: none;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 0;
  /* Formula is: (height / width * 100%) */
  padding-top: calc(960 / 800 * 100%);
}

#HeroImage1800 {
  position: absolute;
  top: 0;
  /* left: 0; */
  width: 100%;
  height: auto;
  animation: zoom-out 12s ease;
  pointer-events: none;
}

#HeroImage1280 {
  position: absolute;
  top: 0;
  /* left: 0; */
  max-width: 100%;
  height: auto;
  display: none;
  animation: zoom-out 12s ease;
  pointer-events: none;
}

#HeroImage800 {
  position: absolute;
  top: 0;
  /* left: 0; */
  max-width: 100%;
  height: auto;
  display: none;
  animation: zoom-out 12s ease;
  pointer-events: none;
}

#HeroLogo {
  position: absolute;
  top: 11%;
  width: 30%;
  animation: fadeIn 2s;
  pointer-events: none;
}

#HeroText {
  text-align: center;
  position: absolute;
  top: 26%;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  text-shadow: 2px 2px 2px white;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white; */
  font-size: 48px;
  color: #02674c;
  animation: fadeIn 4s;
}

#HeroButton {
  position: absolute;
  top: 60%;
  animation: fadeIn 8s;
}

#HomePageDestinationsHeadline {
  margin-top: 5px;
}

#DestinationCardContainer {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}

.ViewAllDestinationsButton {
  margin-top: 15px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1300px) {
  #HeroLogo {
    top: 10%;
  }
  #HeroText {
    top: 25%;
  }
}

@media only screen and (max-width: 1199px) {
  #HeroLogo {
    top: 10%;
    width: 33%;
  }
  #HeroText {
    font-size: 44px;
    top: 26%;
  }
  #HeroButton {
    top: 65%;
  }
  #HeroImageContainer {
    padding-top: calc(960 / 1280 * 100%);
  }
  #HeroImage1800 {
    display: none;
  }
  #HeroImage1280 {
    display: block;
  }
}

@media only screen and (max-width: 1100px) {
  #HeroLogo {
    width: 33%;
  }
}

@media only screen and (max-width: 991px) {
  #HeroButton {
    top: 70%;
  }
  #HeroLogo {
    top: 4%;
    width: 35%;
  }
  #HeroText {
    /* font-size: 36px; */
    top: 21%;
  }
}

@media only screen and (max-width: 800px) {
  #HeroLogo {
    top: 7%;
    width: 50%;
  }
  #HeroText {
    font-size: 42px;
    top: 22%;
  }
  #HeroImageContainer {
    display: none;
  }
  #HeroImageContainerMobile {
    display: flex;
  }
  #HeroImage1280 {
    display: none;
  }
  #HeroImage800 {
    display: flex;
  }
}

@media only screen and (max-width: 700px) {
  #HeroLogo {
    top: 6%;
  }
  #HeroText {
    top: 21%;
  }
}

@media only screen and (max-width: 600px) {
  #HeroLogo {
    top: 4.5%;
  }
  #HeroText {
    top: 20%;
    font-size: 38px;
  }
}

@media only screen and (max-width: 550px) {
  #HeroLogo {
    top: 4%;
  }
  #HeroText {
    top: 19%;
    font-size: 36px;
  }
}

@media only screen and (max-width: 500px) {
  #HeroButton {
    top: 80%;
  }
  #HeroText {
    font-size: 34px;
    top: 21%;
  }
  #HeroLogo {
    width: 55%;
  }
}

@media only screen and (max-width: 450px) {
  #HeroText {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  #HeroLogo {
    top: 4%;
  }
  #HeroText {
    font-size: 28px;
  }
}

@media only screen and (max-width: 375px) {
  #HeroText {
    font-size: 27px;
  }
}

@media only screen and (max-width: 350px) {
  #HeroLogo {
    top: 3.5%;
  }
  #HeroText {
    font-size: 26px;
  }
}

@media only screen and (max-width: 325px) {
  #HeroLogo {
    top: 3%;
    width: 57%;
  }
  #HeroText {
    font-size: 25px;
  }
}

@media only screen and (max-width: 300px) {
  #HeroText {
    font-size: 24px;
  }
}

@media only screen and (max-width: 290px) {
  #HeroText {
    font-size: 23px;
  }
}

@media only screen and (max-width: 275px) {
  #HeroText {
    font-size: 22px;
  }
}

@media only screen and (max-width: 260px) {
  #HeroText {
    font-size: 21px;
  }
}

@media only screen and (max-width: 250px) {
  #HeroText {
    font-size: 18px;
  }
}
