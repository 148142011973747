#AboutMainImageButton {
  position: absolute;
  top: 80%;
  text-align: center;
}

#AboutLogo {
  position: absolute;
  top: 22%;
  width: 30%;
  pointer-events: none;
}

#AboutText {
  position: absolute;
  top: 13%;
}

.BenefitBulletPointsContainer {
  margin-right: 20px;
}

.ClickToLearnMore {
  font-family: "Lato", sans-serif;
  color: white;
  font-size: 16px;
}

@media only screen and (max-width: 1300px) {
  #AboutText {
    top: 14%;
  }
  #AboutLogo {
    top: 24%;
  }
}

@media only screen and (max-width: 1199px) {
  #AboutText {
    top: 15%;
  }
  #AboutLogo {
    top: 25%;
  }
}

@media only screen and (max-width: 991px) {
  #AboutText {
    top: 3%;
  }
  #AboutLogo {
    top: 13%;
  }
}

@media only screen and (max-width: 800px) {
  #AboutLogo {
    top: 13%;
    width: 35%;
  }
  #AboutText {
    top: 2.5%;
  }
}

@media only screen and (max-width: 700px) {
  #AboutLogo {
    width: 37%;
  }
  #AboutText {
    top: 1%;
  }
}

@media only screen and (max-width: 600px) {
  #AboutText {
    top: 8%;
  }
  #AboutLogo {
    top: 14.5%;
    width: 40%;
  }
  #AboutMainImageButton {
    top: 75%;
  }
}

@media only screen and (max-width: 500px) {
  #AboutLogo {
    width: 45%;
    top: 15.5%;
  }
  #AboutText {
    top: 8.5%;
  }
}

@media only screen and (max-width: 450px) {
  #AboutLogo {
    top: 16%;
  }
  .ClickToLearnMore {
    font-size: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .ClickToLearnMore {
    font-size: 14px;
  }
}

@media only screen and (max-width: 375px) {
  #AboutLogo {
    top: 16.5%;
  }
}

@media only screen and (max-width: 350px) {
  #AboutLogo {
    top: 17%;
  }
  #AboutText {
    top: 9%;
  }
}

@media only screen and (max-width: 325px) {
  #AboutLogo {
    width: 47%;
    top: 17%;
  }
  #AboutText {
    top: 9%;
  }
}

@media only screen and (max-width: 270px) {
  #AboutText {
    font-size: 18px;
  }
}
