.RatingContainer {
  justify-content: center;
  width: 100%;
  display: flex;
  cursor: default;
}

#RatingStar {
  background: transparent;
  filter: drop-shadow(2px 2px 2px black);
}

.RatingIcon {
  width: 30px;
  height: 30px;
  margin: 1px;
}

.ChampagneBucket {
  height: 32px;
  margin-left: 1px;
  filter: drop-shadow(0px 0px 3px white);
}

@media only screen and (max-width: 1100px) {
  .RatingIcon {
    width: 27px;
    height: 27px;
  }
  .ChampagneBucket {
    height: 29px;
  }
}

@media only screen and (max-width: 1000px) {
  .RatingIcon {
    width: 30px;
    height: 30px;
  }
  .ChampagneBucket {
    height: 32px;
  }
}

@media only screen and (max-width: 750px) {
  .RatingIcon {
    width: 29px;
    height: 29px;
  }
  .ChampagneBucket {
    height: 31px;
  }
}

@media only screen and (max-width: 700px) {
  .RatingIcon {
    width: 28px;
    height: 28px;
  }
  .ChampagneBucket {
    height: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .RatingIcon {
    width: 37px;
    height: 37px;
  }
  .ChampagneBucket {
    height: 39px;
  }
}

@media only screen and (max-width: 475px) {
  .RatingIcon {
    width: 35px;
    height: 35px;
  }
  .ChampagneBucket {
    height: 37px;
  }
}

@media only screen and (max-width: 425px) {
  .RatingIcon {
    width: 33px;
    height: 33px;
  }
  .ChampagneBucket {
    height: 35px;
  }
}

@media only screen and (max-width: 400px) {
  .RatingIcon {
    width: 32px;
    height: 32px;
  }
  .ChampagneBucket {
    height: 34px;
  }
}

@media only screen and (max-width: 375px) {
  .RatingIcon {
    width: 31px;
    height: 31px;
  }
  .ChampagneBucket {
    height: 33px;
  }
}

@media only screen and (max-width: 350px) {
  .RatingIcon {
    width: 29px;
    height: 29px;
  }
  .ChampagneBucket {
    height: 31px;
  }
}

@media only screen and (max-width: 325px) {
  .RatingIcon {
    width: 28px;
    height: 28px;
  }
  .ChampagneBucket {
    height: 30px;
  }
}

@media only screen and (max-width: 310px) {
  .RatingIcon {
    width: 27px;
    height: 27px;
  }
  .ChampagneBucket {
    height: 29px;
  }
}

@media only screen and (max-width: 300px) {
  .RatingIcon {
    width: 26px;
    height: 26px;
  }
  .ChampagneBucket {
    height: 28px;
  }
}

@media only screen and (max-width: 275px) {
  .RatingIcon {
    width: 24px;
    height: 24px;
  }
  .ChampagneBucket {
    height: 26px;
  }
}

@media only screen and (max-width: 250px) {
  .RatingIcon {
    width: 23px;
    height: 23px;
  }
  .ChampagneBucket {
    height: 25px;
  }
}
