.StarsContainer {
  justify-content: center;
  width: 100%;
}

.DriveTimeChartContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  cursor: default;
}

.DriveTimeChart {
  display: flex;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-family: Anuphan;
  font-size: 18px;
  justify-content: center;
}

.DriveTimeChartHeadline {
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 2px black;
  color: white;
  text-align: center;
  cursor: default;
  margin-bottom: 5px;
  margin-top: 20px;
}

.DriveTimeChartSupplierName {
  color: white;
}

.DriveTimeChartSupplierName:after {
  content: url(../../../../Assets/FlagIcon.png);
  margin-left: 5px;
  visibility: hidden;
}

.DriveTimeChartSupplierName:hover:after {
  visibility: visible;
}

.DriveTimeChartSupplierName:hover {
  text-decoration: none;
  color: white;
  text-shadow: 3px 3px 3px black;
}

.DriveTimeChartSupplierName:active {
  text-decoration: none;
  color: black;
  text-shadow: 2px 2px 2px white;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

@media only screen and (max-width: 450px) {
  table {
    border-collapse: collapse;
    width: 98%;
  }
  .DriveTimeChart {
    font-size: 16px;
  }
  td,
  th {
    padding: 5px;
  }
}

@media only screen and (max-width: 400px) {
  .DriveTimeChartHeadline {
    font-size: 22px;
  }
}

@media only screen and (max-width: 375px) {
  .DriveTimeChartHeadline {
    font-size: 21px;
  }
}

@media only screen and (max-width: 350px) {
  .DriveTimeChartHeadline {
    font-size: 20px;
  }
}

@media only screen and (max-width: 325px) {
  .DriveTimeChartHeadline {
    font-size: 19px;
  }
}
