#BulletPointHeadline {
  list-style-image: url(../../Assets/FlagIcon.png);
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 20px;
  font-family: "Anuphan", sans-serif;
  font-weight: bold;
  cursor: pointer;
}

#BulletPointHeadline:active {
    color: black;
    text-shadow: 2px 2px 2px white;
  }

#BulletPointText {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 18px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  #BenefitBulletPoint {
    font-size: 19px;
  }
}

@media only screen and (max-width: 500px) {
  #BenefitBulletPoint {
    font-size: 18px;
  }
}

@media only screen and (max-width: 450px) {
  #BenefitBulletPoint {
    font-size: 17px;
  }
}

@media only screen and (max-width: 390px) {
  #BenefitBulletPoint {
    font-size: 16px;
  }
}
