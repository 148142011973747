#AccommodationName {
  font-size: 31px;
}

#RatingStarsContainer {
  justify-content: center;
  width: 100%;
  margin: auto;
}

@media only screen and (max-width: 1100px) {
  #AccommodationName {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1000px) {
  #AccommodationName {
    font-size: 31px;
  }
}

@media only screen and (max-width: 750px) {
  #AccommodationName {
    font-size: 30px;
  }
}

@media only screen and (max-width: 700px) {
  #AccommodationName {
    font-size: 29px;
  }
}

@media only screen and (max-width: 600px) {
  #AccommodationName {
    font-size: 39px;
  }
}

@media only screen and (max-width: 475px) {
  #AccommodationName {
    font-size: 37px;
  }
}

@media only screen and (max-width: 425px) {
  #AccommodationName {
    font-size: 35px;
  }
}

@media only screen and (max-width: 400px) {
  #AccommodationName {
    font-size: 33px;
  }
}

@media only screen and (max-width: 375px) {
  #AccommodationName {
    font-size: 32px;
  }
}

@media only screen and (max-width: 350px) {
  #AccommodationName {
    font-size: 30px;
  }
}

@media only screen and (max-width: 325px) {
  #AccommodationName {
    font-size: 29px;
  }
}

@media only screen and (max-width: 310px) {
  #AccommodationName {
    font-size: 28px;
  }
}

@media only screen and (max-width: 300px) {
  #AccommodationName {
    font-size: 27px;
  }
}

@media only screen and (max-width: 275px) {
  #AccommodationName {
    font-size: 26px;
  }
}

@media only screen and (max-width: 250px) {
  #AccommodationName {
    font-size: 25px;
  }
}
