.ScrollButtonContainer {
  margin-top: 5px;
  text-align: center;
}

.ScrollButton {
  background-color: #02674c;
  border: none;
  outline: none;
}

.ScrollButton:focus {
  outline: none;
}

.ScrollButtonText {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  text-shadow: 2px 2px 2px black;
  text-decoration: none;
  cursor: pointer;
}

.ScrollButtonText:before {
  content: url(../../../Assets/FlagIconDropdown.png);
  margin-right: 5px;
  visibility: hidden;
}

.ScrollButtonText:after {
  content: url(../../../Assets/FlagIconDropdown.png);
  margin-left: 5px;
  visibility: hidden;
}

.ScrollButtonText:hover:after {
  visibility: visible;
}

.ScrollButtonText:active {
  text-align: center;
  color: black;
  text-shadow: 2px 2px 2px white;
}
