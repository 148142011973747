.my-thumbs:hover {
    cursor: pointer;
}

swiper-container {
  width: 60%;
  height: 60%;
}

swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-slide-auto {
  &.swiper-slide-thumb-active {
    -webkit-border: 2px solid white;
    border: 2px solid white;
    cursor: default;
  }
}

swiper-theme-color {
  color: #02674c;
}

.ZoomText {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  text-shadow: 2px 2px 2px black;
  text-decoration: none;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 5px;
  cursor: default;
}

@media only screen and (max-width: 1000px) {
  swiper-container {
    width: 70%;
    height: 70%;
  }
}

@media only screen and (max-width: 900px) {
  swiper-container {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (max-width: 800px) {
  swiper-container {
    width: 90%;
    height: 90%;
  }
}

@media only screen and (max-width: 700px) {
  swiper-container {
    width: 95%;
    height: 95%;
  }
}

@media only screen and (max-width: 600px) {
  swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide-auto {
    &.swiper-slide-thumb-active {
      -webkit-border: 2px solid white;
      border: 2px solid white;
    }
  }
}

@media only screen and (max-width: 350px) {
  .ZoomText {
    font-size: 14px;
  }
}
