@import url("https://fonts.googleapis.com/css2?family=Anuphan:wght@400;500;700&display=swap");

@import "~react-image-gallery/styles/css/image-gallery.css";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1.3, 1.3);
  }
  10% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

@-moz-keyframes zoom-out {
  0% {
    transform: scale(1.3, 1.3);
  }
  10% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes zoom-out {
  0% {
    transform: scale(1.3, 1.3);
  }
  10% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

@-o-keyframes zoom-out {
  0% {
    transform: scale(1.3, 1.3);
  }
  10% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

@-ms-keyframes zoom-out {
  0% {
    transform: scale(1.3, 1.3);
  }
  10% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

.grecaptcha-badge {
  opacity: 0;
}

body {
  background-color: #02674c;
  /* overflow-x: hidden; */
  height: 100%;
  overscroll-behavior: none;
}

.ButtonContainer {
  text-align: center;
  margin-top: 10px;
}

.CardContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.img-protect {
  position: relative;
  overflow: hidden;
}

.img-protect::before {
  content: '';
  isolation: isolate;
  position: absolute;
  inset: 0;
  z-index: 10;
}

.MainImageButtonContainer {
  position: absolute;
  top: 80%;
  text-align: center;
}

.MainImageContainer {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 0;
  /* Formula is: (height / width * 100%) */
  padding-top: calc(900 / 1600 * 100%);
}

.MainImageContainerMobile {
  height: 0;
  /* Formula is: (height / width * 100%) */
  padding-top: calc(1000 / 800 * 100%);
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  display: none;
}

.MainImage {
  position: absolute;
  top: 0;
  height: auto;
  width: 100%;
  left: 0;
  animation: zoom-out 12s ease;
  overflow: hidden;
  pointer-events: none;
}

.MainImageText {
  position: absolute;
  text-align: center;
  top: 15%;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  text-shadow: 2px 2px 2px white;
  font-size: 48px;
  color: #02674c;
  /* padding: 5px; */
  animation: fadeIn 2s;
  width: 80%;
}

.MainImageTMSign {
  font-size: 24px;
  text-shadow: none;
  -webkit-text-stroke-width: 0px;
  vertical-align: super;
}

.MobileBreak {
  display: none;
}

.NavigationBufferZone {
  border-bottom: white;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-style: solid;
  border-width: 2px;
  height: 70px;
}

.PageHeadline {
  font-family: Lato;
  font-size: 44px;
  font-weight: bold;
  text-shadow: 3px 3px 3px black;
  color: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: default;
}

.PageHeadlineFlag {
  content: url(../src/Assets/FlagIconLarger.png);
  height: 25px;
  margin-bottom: -2px;
}

.PageHeadlineTMSign {
  font-size: 26px;
}

.PageSubHeadline {
  font-family: Lato;
  font-size: 32px;
  font-weight: bold;
  text-shadow: 3px 3px 3px black;
  color: white;
  text-align: center;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  cursor: default;
}

.PageSubHeadlineTMSign {
  font-size: 18px;
}

#SortingButtonsRow {
  margin-top: -10px;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.SectionScrollButton {
  display: none;
  text-align: center;
}

.SectionBackButton {
  display: none;
}

.SortingButton {
  margin-left: 2px;
  margin-right: 2px;
}

@media only screen and (max-width: 1199px) {
  .MainImageText {
    font-size: 42px;
  }
  .MainImageTMSign {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1100px) {
  .MainImageText {
    font-size: 40px;
  }
  .MainImageTMSign {
    font-size: 21px;
  }
}

@media only screen and (max-width: 991px) {
  .MainImageButtonContainer {
    top: 75%;
  }
  .MainImageText {
    font-size: 35px;
    top: 8%;
    width: 95%;
  }
  .MainImageTMSign {
    font-size: 18px;
  }
  .PageHeadline {
    font-size: 38px;
  }
  .PageHeadlineTMSign {
    font-size: 22px;
  }
  .NavigationBufferZone {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .MainImageTMSign {
    font-size: 17px;
  }
  .PageHeadline {
    font-size: 37px;
  }
}

@media only screen and (max-width: 850px) {
  .MainImageTMSign {
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .MainImageText {
    font-size: 34px;
  }
  .MainImageTMSign {
    font-size: 18px;
  }
  .PageHeadline {
    font-size: 36px;
  }
  .PageHeadlineFlag {
    height: 22px;
  }
  .PageSubHeadline {
    font-size: 31px;
  }
}

@media only screen and (max-width: 767px) {
  .MainImageTMSign {
    font-size: 17px;
  }
}

@media only screen and (max-width: 750px) {
  .MainImageText {
    font-size: 33px;
  }
  .MainImageTMSign {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .MainImageText {
    font-size: 32px;
  }
  .PageHeadline {
    font-size: 35px;
  }
  .PageSubHeadline {
    font-size: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .MainImageButtonContainer {
    top: 85%;
  }
  .MainImageContainer {
    display: none;
  }
  .MainImageContainerMobile {
    display: flex;
  }
  .MainImageText {
    font-size: 31px;
    top: 6%;
  }
  .MainImageTMSign {
    font-size: 15px;
  }
  .PageHeadline {
    font-size: 34px;
  }
  .PageHeadlineTMSign {
    font-size: 20px;
  }
  .SectionScrollButton {
    display: block;
  }
  .SectionBackButton {
    display: flex;
  }
}

@media only screen and (max-width: 575px) {
  .MainImageText {
    font-size: 30px;
  }
}

@media only screen and (max-width: 550px) {
  .MainImageText {
    font-size: 29px;
  }
  .PageHeadline {
    font-size: 33px;
  }
  .PageSubHeadline {
    font-size: 29px;
  }
}

@media only screen and (max-width: 500px) {
  .MainImageText {
    top: 7%;
  }
  .MainImageTMSign {
    font-size: 14px;
  }
  .PageHeadline {
    font-size: 32px;
  }
  .PageHeadlineFlag {
    height: 21px;
  }
  .PageSubHeadline {
    font-size: 28px;
  }
  .PageHeadlineTMSign {
    font-size: 19px;
  }
}

@media only screen and (max-width: 475px) {
  .PageHeadline {
    font-size: 31px;
  }
  .PageSubHeadlineTMSign {
    font-size: 16px;
  }
}

@media only screen and (max-width: 450px) {
  .MainImageText {
    font-size: 28px;
  }
  .MainImageTMSign {
    font-size: 15px;
  }
  .PageHeadline {
    font-size: 30px;
  }
  .PageHeadlineTMSign {
    font-size: 17px;
  }
}

@media only screen and (max-width: 425px) {
  .MainImageText {
    font-size: 26px;
  }
  .PageHeadline {
    font-size: 29px;
  }
  .PageHeadlineFlag {
    height: 21px;
  }
  .PageSubHeadline {
    font-size: 27px;
  }
}

@media only screen and (max-width: 415px) {
  .PageHeadline {
    font-size: 28px;
  }
  .PageSubHeadline {
    font-size: 26px;
  }
}

@media only screen and (max-width: 400px) {
  .MainImageText {
    font-size: 25px;
  }
  .MainImageTMSign {
    font-size: 14px;
  }
  .PageHeadline {
    font-size: 27px;
  }
  .PageHeadlineFlag {
    height: 20px;
  }
  .PageSubHeadline {
    font-size: 25px;
  }
  .PageHeadlineTMSign {
    font-size: 16px;
  }
}

@media only screen and (max-width: 390px) {
  .PageHeadline {
    font-size: 26px;
  }
  .PageHeadlineFlag {
    height: 19px;
  }
  .PageSubHeadline {
    font-size: 24px;
  }
}

@media only screen and (max-width: 375px) {
  .MainImageText {
    font-size: 24px;
  }
  .PageHeadline {
    font-size: 25px;
    text-shadow: 2px 2px 2px black;
  }
  .PageHeadlineFlag {
    height: 18px;
  }
  .PageSubHeadline {
    font-size: 23px;
  }
  .PageSubHeadlineTMSign {
    font-size: 15px;
  }
}

@media only screen and (max-width: 365px) {
  .PageHeadline {
    font-size: 24px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .PageHeadlineFlag {
    height: 17px;
    margin-bottom: -1px;
  }
  .PageSubHeadline {
    font-size: 22px;
  }
  .PageHeadlineTMSign {
    font-size: 15px;
  }
  .PageSubHeadlineTMSign {
    font-size: 14px;
  }
}

@media only screen and (max-width: 350px) {
  .MainImageText {
    font-size: 23px;
  }
  .MainImageTMSign {
    font-size: 13px;
  }
  .PageHeadline {
    font-size: 23px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .PageHeadlineFlag {
    height: 16px;
  }
  .PageSubHeadline {
    font-size: 21px;
  }
  .PageSubHeadlineTMSign {
    font-size: 13px;
  }
}

@media only screen and (max-width: 335px) {
  .PageHeadline {
    font-size: 22px;
  }
  .PageSubHeadline {
    font-size: 20px;
  }
  .PageSubHeadlineTMSign {
    font-size: 12px;
  }
}

@media only screen and (max-width: 325px) {
  .MainImageText {
    font-size: 22px;
  }
  .MainImageTMSign {
    font-size: 11px;
  }
  .PageHeadline {
    font-size: 21px;
  }
  .PageSubHeadline {
    font-size: 19px;
  }
}

@media only screen and (max-width: 310px) {
  .PageHeadline {
    font-size: 24px;
  }
  .PageSubHeadline {
    font-size: 22px;
  }
  .PageSubHeadlineTMSign {
    font-size: 14px;
  }
  .PageHeadlineTMSign {
    font-size: 14px;
  }
}

@media only screen and (max-width: 300px) {
  .MainImageText {
    font-size: 21px;
  }
  .PageHeadline {
    font-size: 22px;
  }
  .PageSubHeadline {
    font-size: 21px;
  }
  .PageSubHeadlineTMSign {
    font-size: 13px;
  }
}

@media only screen and (max-width: 280px) {
  .PageHeadline {
    font-size: 21px;
  }
  .PageHeadlineFlag {
    height: 15px;
  }
  .PageSubHeadline {
    font-size: 20px;
  }
  .PageSubHeadlineTMSign {
    font-size: 12px;
  }
}

@media only screen and (max-width: 275px) {
  .MainImageText {
    font-size: 20px;
  }
}
