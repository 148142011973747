#Navbar {
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

#NavbarLogo {
  width: 150px;
  pointer-events: none;
}

.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255);
  border-color: rgba(255, 255, 255);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='3.0' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline-color: black;
}

.navbar-nav .dropdown-menu {
  right: 0;
  left: -20%;
  text-align: left;
  margin: auto;
}

.navbar-nav .nav-link {
  line-height: 10px;
  text-align: center;
}

.NavLink {
  color: white !important;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-left: 11px;
  text-shadow: 2px 2px 2px black;
}

.NavLink:before {
  content: url(../../Assets/FlagIcon.png);
  margin-left: 4px;
  visibility: hidden;
}

.NavLink:after {
  content: url(../../Assets/FlagIcon.png);
  margin-left: 4px;
  visibility: hidden;
  margin-right: 10px;
}

.NavLink:hover {
  text-decoration: none;
}

.NavLink:active {
  color: black !important;
  text-shadow: white 1px 1px 1px;
}

.NavLink:hover::after {
  visibility: visible;
}

#Nav {
  text-align: center;
}

#DestinationsLink {
  margin-left: 2px;
}

@media only screen and (max-width: 1500px) {
  #NavbarLogo {
    width: 135px;
  }
  .NavLink {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  #Navbar {
    background: #02674c;
    border-left: transparent;
    border-right: transparent;
    border-top: transparent;
    position: relative;
    border-bottom: white;
    border-style: solid;
    border-width: 2px;
  }
  #Nav {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 450px) {
  .NavLink {
    font-size: 18px;
  }
}

@media only screen and (max-width: 350px) {
  .navbar-light .navbar-toggler-icon {
    height: 25px;
    width: 25px;
  }
  .NavLink {
    font-size: 17px;
  }
  /* .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2.0' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  } */
}
