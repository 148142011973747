.Legal {
    margin: auto;
    text-align: justify;
    color: white;
    font-family: "Lato";
    cursor: default;
  }

  .LegalLink {
    color: white;
    text-decoration: underline;
  }

  .LegalLink:hover {
    color: white;
    text-shadow: 1px 1px 1px black;
  }

  .LastModified {
    text-shadow: 1px 1px 1px black;
  }
  
  .LegalHeadline {
    font-family: "Lato";
    font-size: 24px;
    text-align: center;
    text-shadow: 2px 2px 2px black;
  }

  .LegalBackToTop {
    cursor: pointer;
    color: white;
    text-align: center;
  }

  .LegalBackToTop:hover {
    text-shadow: 1px 1px 1px black;
  }
  
  .LegalText {
    font-size: 14px;
    margin: 20px;
  }
  
  .InItalics {
    font-style: italic;
  }
  
  .LegalSectionHeadline {
    text-decoration: underline;
    text-shadow: 1px 1px 1px black;
  }
  
  @media only screen and (max-width: 1200px) {
    .LegalHeadline {
      font-size: 20px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .LegalHeadline {
      font-size: 18px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .LegalHeadline {
      font-size: 16px;
    }
    .LegalText {
      margin: 10px;
    }
    
  }
  
  @media only screen and (max-width: 400px) {
    .LegalHeadline {
      font-size: 14px;
    }
  }
  
  @media only screen and (max-width: 300px) {
    .LegalText {
      margin: 5px;
    }
  }
  