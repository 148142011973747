#EstablishmentName {
  top: 30%;
}

#EstablishmentSpecialty {
  display: flex;
  font-size: 38px;
  justify-content: center;
  cursor: default;
  text-shadow: none;
}

#EstablishmentSpecialty:hover {
  text-shadow: none;
}

#RestaurantCardBritishAleEmoji {
  height: 50px;
  margin-bottom: 5px;
}

.RestaurantFoodSpecialEmoji {
  margin-top: 5px;
  margin-left: 5px;
  height: 45px;
}

#RestaurantPubCardLocationFlag {
  margin-left: 5px;
  margin-top: 10px;
}

@media only screen and (max-width: 1100px) {
  #EstablishmentSpecialty {
    font-size: 36px;
  }
  #RestaurantCardBritishAleEmoji {
    height: 45px;
  }
}

@media only screen and (max-width: 1000px) {
  #EstablishmentSpecialty {
    font-size: 38px;
  }
  #RestaurantCardBritishAleEmoji {
    height: 50px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 750px) {
  #EstablishmentSpecialty {
    font-size: 37px;
  }
  #RestaurantCardBritishAleEmoji {
    height: 45px;
  }
}

@media only screen and (max-width: 700px) {
  #EstablishmentSpecialty {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  #EstablishmentSpecialty {
    font-size: 46px;
  }
  #RestaurantCardBritishAleEmoji {
    height: 60px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 475px) {
  #EstablishmentSpecialty {
    font-size: 44px;
  }
}

@media only screen and (max-width: 450px) {
  #RestaurantCardBritishAleEmoji {
    height: 50px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 425px) {
  #EstablishmentSpecialty {
    font-size: 42px;
  }
}

@media only screen and (max-width: 400px) {
  #EstablishmentSpecialty {
    font-size: 40px;
  }
}

@media only screen and (max-width: 375px) {
  #EstablishmentSpecialty {
    font-size: 39px;
  }
  #RestaurantCardBritishAleEmoji {
    height: 45px;
  }
}

@media only screen and (max-width: 350px) {
  #EstablishmentSpecialty {
    font-size: 37px;
  }
}

@media only screen and (max-width: 325px) {
  #EstablishmentSpecialty {
    font-size: 32px;
  }
  #RestaurantCardBritishAleEmoji {
    height: 40px;
  }
}

@media only screen and (max-width: 30px) {
  #RestaurantCardBritishAleEmoji {
    height: 35px;
  }
}
