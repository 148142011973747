#SubscribeForm {
  margin-top: 20px;
}

.SubscribeFieldTitle {
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 18px;
  font-family: "Anuphan", sans-serif;
  font-weight: 500;
}

.SubscribeFieldTitle:before {
  content: url(../../Assets/FlagIcon.png);
  height: 16px;
  margin-right: 5px;
}

#SubscribeHeadlineFlag {
  content: url(../../Assets/FlagIconLarger.png);
  height: 17px;
  margin-bottom: -2px;
}

#SubscribeTMSign {
  font-size: 14px;
}

#SubscribeFields {
  width: 100%;
  margin: auto;
}

#SubscribeHeadline {
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 2px black;
  color: white;
  text-align: center;
  cursor: default;
  margin-bottom: 5px;
}

@media only screen and (max-width: 400px) {
  #SubscribeHeadline {
    font-size: 22px;
  }
  #SubscribeHeadlineFlag {
    height: 16px;
    margin-bottom: -1px;
  }
  .SubscribeFieldTitle {
    font-size: 16px;
  }
}

@media only screen and (max-width: 375px) {
  #SubscribeHeadline {
    font-size: 21px;
  }
}

@media only screen and (max-width: 350px) {
  #SubscribeHeadline {
    font-size: 20px;
  }
  #SubscribeHeadlineFlag {
    height: 15px;
  }
}

@media only screen and (max-width: 325px) {
  #SubscribeHeadline {
    font-size: 19px;
  }
  #SubscribeHeadlineFlag {
    height: 14px;
  }
}
