#DestinationsAll {
  overflow-x: hidden;
}

.NavigationBufferZone {
  border-bottom: white;
  border-top: none;
  border-style: solid;
  border-width: 2px;
  height: 70px;
}

#DestinationsPageCardContainer {
  margin-bottom: 10px;
}

#MoreComingSoon {
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 20px;
  font-family: "Anuphan", sans-serif;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 991px) {
  #DestinationsAllBufferZone {
    display: none;
  }
}
