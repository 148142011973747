#HomeTextMobile {
  display: none;
}

#WelcomeLine1 {
  color: white;
  font-size: 60px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  text-shadow: 3px 3px 3px black;
  text-align: center;
  width: 100%;
  margin-left: 10px;
  line-height: 75px;
}

#WelcomeLine2 {
  text-align: center;
  width: 100%;
  font-size: 40px;
  line-height: 35px;
  color: white;
  font-family: "Lato", sans-serif;
  margin-left: 8px;
  text-shadow: 3px 3px 3px black;
  margin-bottom: 20px;
}

#GGOGText {
  font-family: "Anuphan", sans-serif;
  text-shadow: none;
  font-weight: bold;
  margin-left: 10px;
  font-size: 68px;
}

#HomeofGolfTravelText {
  font-weight: bold;
}

#FlagIconWelcome {
  height: 50px;
  margin-bottom: 11px;
}

#RegisteredSign {
  font-size: 24px;
  margin-top: 30px;
}

#TrademarkSign {
  font-size: 24px;
  margin-left: 2px;
}

@media only screen and (max-width: 800px) {
  #WelcomeLine1 {
    font-size: 52px;
    line-height: 70px;
  }

  #WelcomeLine2 {
    font-size: 34px;
    line-height: 30px;
  }

  #GGOGText {
    font-size: 60px;
  }

  #FlagIconWelcome {
    height: 45px;
    margin-bottom: 11px;
  }

  #RegisteredSign {
    font-size: 20px;
  }

  #TrademarkSign {
    font-size: 20px;
    margin-left: 3px;
  }
}

@media only screen and (max-width: 600px) {
  #WelcomeLine1 {
    font-size: 44px;
    text-shadow: 2px 2px 2px black;
    line-height: 55px;
  }

  #WelcomeLine2 {
    font-size: 30px;
    text-shadow: 2px 2px 2px black;
    line-height: 30px;
  }

  #GGOGText {
    font-size: 48px;
  }

  #FlagIconWelcome {
    height: 35px;
    margin-bottom: 8px;
  }

  #HomeTextDesktop {
    display: none;
  }

  #HomeTextMobile {
    display: block;
  }

  #RegisteredSign {
    font-size: 18px;
  }

  #TrademarkSign {
    font-size: 18px;
    margin-left: 2px;
  }
}

@media only screen and (max-width: 500px) {
  #WelcomeLine1 {
    font-size: 42px;
    line-height: 50px;
  }

  #WelcomeLine2 {
    font-size: 28px;
    line-height: 27px;
  }

  #GGOGText {
    font-size: 46px;
  }

  #FlagIconWelcome {
    height: 35px;
    margin-bottom: 8px;
  }

  #RegisteredSign {
    font-size: 18px;
  }

  #TrademarkSign {
    font-size: 18px;
    margin-left: 2px;
  }
}

@media only screen and (max-width: 450px) {
  #WelcomeLine1 {
    font-size: 37px;
    text-shadow: 2px 2px 2px black;
    line-height: 45px;
  }

  #WelcomeLine2 {
    font-size: 26px;
    line-height: 26px;
  }

  #GGOGText {
    font-size: 41px;
  }

  #FlagIconWelcome {
    height: 29px;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 400px) {
  #WelcomeLine1 {
    font-size: 35px;
    text-shadow: 2px 2px 2px black;
    line-height: 45px;
  }

  #WelcomeLine2 {
    line-height: 23px;
    font-size: 24px;
  }

  #GGOGText {
    font-size: 39px;
  }

  #FlagIconWelcome {
    height: 27px;
    margin-bottom: 3px;
  }

  #TrademarkSign {
    font-size: 16px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 375px) {
  #WelcomeLine1 {
    font-size: 32px;
    text-shadow: 2px 2px 2px black;
  }

  #WelcomeLine2 {
    line-height: 20px;
  }

  #GGOGText {
    font-size: 35px;
  }

  #FlagIconWelcome {
    height: 25px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 350px) {
  #WelcomeLine1 {
    font-size: 30px;
    text-shadow: 2px 2px 2px black;
  }

  #WelcomeLine2 {
    line-height: 18px;
  }

  #GGOGText {
    font-size: 33px;
  }

  #FlagIconWelcome {
    height: 24px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 325px) {
  #WelcomeLine1 {
    font-size: 28px;
  }

  #GGOGText {
    font-size: 31px;
  }

  #FlagIconWelcome {
    height: 22px;
    margin-bottom: 4px;
  }

  #WelcomeLine2 {
    font-size: 22px;
    line-height: 16px;
  }
}

@media only screen and (max-width: 310px) {
  #WelcomeLine1 {
    font-size: 26px;
    /* line-height: 40px; */
  }

  #GGOGText {
    font-size: 29px;
  }

  #FlagIconWelcome {
    height: 20px;
    margin-bottom: 3px;
  }

  #WelcomeLine2 {
    font-size: 21px;
    line-height: 12px;
  }
}

@media only screen and (max-width: 290px) {
  #WelcomeLine1 {
    font-size: 24px;
    /* line-height: 40px; */
  }

  #GGOGText {
    font-size: 27px;
  }

  #FlagIconWelcome {
    height: 19px;
    margin-bottom: 3px;
  }

  #WelcomeLine2 {
    font-size: 20px;
    line-height: 10px;
  }
}

@media only screen and (max-width: 270px) {
  #WelcomeLine1 {
    font-size: 20px;
    /* line-height: 40px; */
  }

  #GGOGText {
    font-size: 23px;
  }

  #FlagIconWelcome {
    height: 18px;
    margin-bottom: 5px;
  }

  #WelcomeLine2 {
    font-size: 16px;
    line-height: 6px;
  }
}
