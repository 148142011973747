#NavigationBottom {
    margin: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    overflow-x: hidden;
  }

  #NavigationBottomLinksContainer {
    margin-top: 10px;
  }
  
  .NavigationBottomLinks {
    color: white;
    margin: auto;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
  }
  
  .NavigationBottomLinks:hover {
    color: white;
    text-decoration: none;
  }
  

  .NavigationBottomLinks:active {
    color: black;
    text-shadow: 1px 1px 1px white;
    text-decoration: none;
  }

  .NavigationBottomLinks:before {
    content: url(../../../Assets/FlagIcon.png);
    margin-left: 4px;
    visibility: hidden;
  }
  
  .NavigationBottomLinks:after {
    content: url(../../../Assets/FlagIcon.png);
    margin-left: 4px;
    visibility: hidden;
  }

  .NavigationBottomLinks:hover::after {
    visibility: visible;
  }