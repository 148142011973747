#HomeThankYou {
  overflow-x: hidden;
}

#HeroLogoThankYou {
  position: absolute;
  top: 15%;
  width: 30%;
  pointer-events: none;
}

#HeroTextThankYou {
  text-align: center;
  position: absolute;
  top: 31%;
  animation: fadeIn 2s;
}

#ThankYouText2 {
  text-align: center;
  position: absolute;
  top: 55%;
  animation: fadeIn 4s;
}

@media only screen and (max-width: 1199px) {
  #HeroLogoThankYou {
    top: 15%;
    width: 33%;
  }
  #HeroTextThankYou {
    top: 33%;
  }
  #ThankYouText2 {
    top: 60%;
  }
}

@media only screen and (max-width: 991px) {
  #HeroLogoThankYou {
    top: 6%;
    width: 35%;
  }
  #HeroTextThankYou {
    /* font-size: 36px; */
    top: 23%;
  }
}

@media only screen and (max-width: 800px) {
  #HeroLogoThankYou {
    top: 7%;
    width: 50%;
  }
  #HeroTextThankYou {
    top: 23%;
  }
  /* #HeroImageContainer {
    padding-top: calc(960 / 800 * 100%);
  } */
}

@media only screen and (max-width: 700px) {
  #HeroLogoThankYou {
    top: 6%;
  }
  #HeroTextThankYou {
    top: 21%;
  }
}

@media only screen and (max-width: 600px) {
  #HeroLogoThankYou {
    top: 4.5%;
  }
  #HeroTextThankYou {
    top: 20%;
  }
}

@media only screen and (max-width: 550px) {
  #HeroLogoThankYou {
    top: 4%;
  }
  #HeroTextThankYou {
    top: 19%;
  }
}

@media only screen and (max-width: 500px) {
  #HeroTextThankYou {
    top: 21%;
  }
  #HeroLogoThankYou {
    width: 55%;
  }
}

@media only screen and (max-width: 400px) {
  #HeroLogoThankYou {
    top: 4%;
  }
}

@media only screen and (max-width: 350px) {
  #HeroLogoThankYou {
    top: 3.5%;
  }
}

@media only screen and (max-width: 325px) {
  #HeroLogoThankYou {
    top: 3%;
    width: 57%;
  }
}
