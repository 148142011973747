#CourseName {
  font-size: 31px;
}

#CourseRatingHeadline {
  text-align: center;
  color: white;
  font-size: 18px;
  font-family: Anuphan;
  font-weight: bold;
  text-shadow: 2px 2px 2px black;
  width: 100%;
  line-height: 10px;
  margin-top: 5px;
  margin-bottom: -5px;
  cursor: default;
}

#FlagsContainer {
  /* justify-content: center; */
  /* position: absolute; */
  /* top: 53%; */
  width: 100%;
  margin: auto;
  /* line-height: 15px; */
}

#FlagIconTransparent {
  height: 13px;
  width: 13px;
  margin-bottom: 1px;
  filter: drop-shadow(2px 2px 2px black);
}

@media only screen and (max-width: 1100px) {
  #CourseName {
    font-size: 28px;
  }
  #CourseRatingHeadline {
    font-size: 16px;
  }
  #FlagIconTransparent {
    height: 12px;
    width: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  #CourseName {
    font-size: 31px;
  }
  #CourseRatingHeadline {
    font-size: 18px;
  }
  #FlagIconTransparent {
    height: 13px;
    width: 13px;
    margin-bottom: 2px;
  }
}

@media only screen and (max-width: 750px) {
  #CourseName {
    font-size: 30px;
  }
  #CourseRatingHeadline {
    font-size: 17px;
  }
}

@media only screen and (max-width: 700px) {
  #CourseName {
    font-size: 29px;
  }
  #CourseRatingHeadline {
    font-size: 16px;
  }
  #FlagIconTransparent {
    height: 12px;
    width: 12px;
  }
}

@media only screen and (max-width: 600px) {
  #CourseName {
    font-size: 39px;
  }
  #CourseRatingHeadline {
    font-size: 26px;
  }
  #FlagIconTransparent {
    height: 16px;
    width: 16px;
  }
}

@media only screen and (max-width: 475px) {
  #CourseName {
    font-size: 37px;
  }
  #CourseRatingHeadline {
    font-size: 24px;
  }
  #FlagIconTransparent {
    height: 15px;
    width: 15px;
  }
}

@media only screen and (max-width: 425px) {
  #CourseName {
    font-size: 35px;
  }
  #CourseRatingHeadline {
    font-size: 22px;
  }
  #FlagIconTransparent {
    height: 13px;
    width: 13px;
  }
}

@media only screen and (max-width: 400px) {
  #CourseName {
    font-size: 33px;
  }
  #CourseRatingHeadline {
    font-size: 20px;
  }
  #FlagIconTransparent {
    height: 12px;
    width: 12px;
  }
}

@media only screen and (max-width: 375px) {
  #CourseName {
    font-size: 32px;
  }
  #CourseRatingHeadline {
    font-size: 18px;
  }
  #FlagIconTransparent {
    height: 11px;
    width: 11px;
  }
}

@media only screen and (max-width: 350px) {
  #CourseName {
    font-size: 30px;
  }
  #CourseRatingHeadline {
    font-size: 17px;
    /* top: 46%; */
  }
  /* #FlagsContainer {
    top: 54%;
  } */
}

@media only screen and (max-width: 325px) {
  #CourseName {
    font-size: 28px;
  }
  #FlagIconTransparent {
    height: 10px;
    width: 10px;
  }
}

@media only screen and (max-width: 310px) {
  #CourseName {
    font-size: 27px;
  }
}

@media only screen and (max-width: 300px) {
  #CourseName {
    font-size: 26px;
  }
  #FlagIconTransparent {
    height: 9px;
    width: 9px;
  }
}

@media only screen and (max-width: 290px) {
  #CourseName {
    font-size: 25px;
  }
  #CourseRatingHeadline {
    font-size: 16px;
  }
}

@media only screen and (max-width: 275px) {
  #CourseName {
    font-size: 23px;
  }
  #CourseRatingHeadline {
    font-size: 15px;
  }
}

@media only screen and (max-width: 250px) {
  #CourseName {
    font-size: 22px;
  }
  #CourseRatingHeadline {
    font-size: 13px;
  }
  #FlagIconTransparent {
    height: 8px;
    width: 8px;
  }
}
