.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.my-node-exit {
  opacity: 0;
}
.my-node-exit-active {
  opacity: 1;
  transition: opacity 500ms;
}

.MainTextMobile {
  display: none;
}

.MainText {
  color: white;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  text-align: justify;
  cursor: default;
}

.ToggleFullTextButton {
  border-radius: 5px;
  color: black;
  text-shadow: 1px 1px 1px white;
  background-color: #02674c;
  border: 1px solid#02674c;
  outline: none !important;
  font-size: 18px;
  font-family: Anuphan;
  font-weight: 700;
  cursor: pointer;
  margin-left: 5px;
}

.ToggleFullTextButton:after {
  content: url(../../Assets/FlagIconDropdown.png);
  visibility: hidden;
  margin-left: 3px;
}

.ToggleFullTextButton:hover:after {
  visibility: visible;
}

.ToggleFullTextButton:active {
  color: white;
  text-shadow: 2px 2px 2px black;
}

@media only screen and (max-width: 800px) {
  .MainTextDesktop {
    display: none;
  }
  .MainTextMobile {
    display: block;
  }
}

@media only screen and (max-width: 450px) {
  .MainText {
    /* font-size: 15px; */
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .MainText {
    /* font-size: 14px; */
    padding-left: 8px;
    padding-right: 8px;
  }
}