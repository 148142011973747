#SecondaryButton {
  border-radius: 5px;
  color: #02674c;
  background-color: #fafbfd;
  border: 1px solid#fafbfd;
  outline: none !important;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  cursor: pointer;
}

#SecondaryButton:before {
  content: url(../../../Assets/FlagIconDropdown.png);
  margin-right: 5px;
  margin-left: 3px;
  visibility: hidden;
}

#SecondaryButton:after {
  content: url(../../../Assets/FlagIconDropdown.png);
  margin-left: 5px;
  margin-right: 3px;
  visibility: hidden;
}

#SecondaryButton:hover:after {
  visibility: visible;
}

#SecondaryButton:active {
  border: 1px solid white;
  color: white;
  text-shadow: 1px 1px 1px black;
  background-color: #02674c;
}

#SecondaryButtonLinkText {
  color: #02674c;
  text-decoration: none;
}

#SecondaryButtonLinkText:active {
  color: white;
  text-shadow: 1px 1px 1px black;
}
